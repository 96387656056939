import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { AltAboutJk } from "@components/AboutJk"
import { Button } from "@components/Button"
import { GatedForm } from "@components/Form"
import { Layout } from "@components/Layout"
import { Lottie } from "@components/Lottie"
import { Arrow } from "@components/UI"
/*global gtag*/
import { Seo } from "@components/Seo"
import { usePageSetup } from "@components/usePageSetup"
import dataLottie from "@lottie/data_b.json"
import funnelLottie from "@lottie/funnel_b.json"
import heartPersonLottie from "@lottie/heart_person_b.json"
import repeatLottie from "@lottie/repeat_b.json"
import { splitText } from "@utils"
import { graphql } from "gatsby"
import React from "react"

const EmployerBrandBuyInGuidePage = ({ data }) => {
  const { employerBrandingDesktop, employerBrandingMobile, buyInGuideDesktop, buyInGuideMobile } = data
  const { themeColor } = usePageSetup({ themeColor: "bg-teal-light", displayGeneralForm: false })
  const downloadGuide = () => {
    const isBrowser = typeof window !== "undefined"
    if (!isBrowser) {
      return
    }
    gtag("event", "download_form_submission", { event_category: "download", event_label: "EB_buy_in_guide" })
    window.open("/documents/jk-employer-branding-buy-in-guide.pdf", "_blank").focus()
  }

  return (
    <Layout>
      <Seo title="Employer Brand Buy-in-Guide" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className="container relative grid-hci">
          <div className="relative hci-hero pt-clamp-14-25 pb-clamp-21-29">
            <div className="absolute inset-0">
              <div className={`w-full h-full ${themeColor} extend before:bg-teal-light after:bg-teal-light`}></div>
            </div>
            <h1
              className="text-gated-title"
              data-aos="stagger"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": "Free guide:",
                  "text-gray-darkest": "Creating internal buy-in for your employer brand.",
                }),
              }}
            />
          </div>
          <div className="z-10 flex flex-col hci-sidebar lg:pt-40">
            <div className="relative lg:px-8 xl:px-14">
              <div className="absolute inset-0 flex lg:hidden">
                <div className="w-full bg-teal-light extend before:bg-teal-light after:bg-teal-light lg:extend-none"></div>
              </div>
              <div className="absolute inset-0 flex">
                <div className="w-full mt-auto bg-teal extend before:bg-teal after:bg-teal lg:extend-none aspect-w-468 aspect-h-189"></div>
              </div>
              <div className="shadow">
                <div className="hidden lg:block">
                  <GatsbyImage image={getImage(buyInGuideDesktop)} alt="Buy-in Guide" />
                </div>
                <div className="lg:hidden">
                  <GatsbyImage image={getImage(buyInGuideMobile)} alt="Buy-in Guide" />
                </div>
              </div>
            </div>
            <div className="relative w-full pb-20 lg:px-8 xl:px-14 pt-14">
              <div className="absolute inset-0">
                <div className="w-full h-full bg-teal extend before:bg-teal after:bg-teal lg:extend-none"></div>
              </div>
              <div className="relative">
                <h3 className="text-26">Share some basic info to access your guide</h3>
                <div className="mt-14">
                  <GatedForm
                    id="785"
                    subject="HCI Buy In Guide"
                    recipient="katiek@jkdesign.com, markm@jkdesign.com, chrisb@jkdesign.com"
                    fn={downloadGuide}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:hidden">
            <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          </div>
          <div className="hci-about pt-clamp-46-28 lg:pt-28 pb-28">
            <div data-aos="fade-up">
              <p>
                Help your leaders understand the value of building an employer brand and remove barriers to buy-in with
                this free guide.
              </p>
              <p className="font-semi">It will help you communicate the benefits of an employer brand, including:</p>
            </div>

            <div className="grid md:grid-cols-2 gap-x-4 gap-y-clamp-14-24 mt-clamp-20-23">
              <div data-aos="fade-up" className="md:pr-20">
                <div>
                  <Lottie animation={dataLottie} />
                </div>
                <p className="mt-5 text-18">Why employer branding is so crucial in today’s climate</p>
              </div>
              <div data-aos="fade-up" className="md:px-8">
                <div>
                  <Lottie animation={funnelLottie} />
                </div>
                <p className="mt-5 text-18">Data-driven business benefits of employer branding</p>
              </div>
              <div data-aos="fade-up" className="md:pr-20">
                <div>
                  <Lottie animation={repeatLottie} />
                </div>
                <p className="mt-5 text-18">How an employer brand boosts the bottom line</p>
              </div>
              <div data-aos="fade-up" className="md:px-8">
                <div>
                  <Lottie animation={heartPersonLottie} />
                </div>
                <p className="mt-5 text-18">The short- and long-term benefits of an employer brand </p>
              </div>
            </div>
          </div>
        </div>
        <main>
          <section className="lg:mt-clamp-26-32">
            <div className="relative flex lg:hidden">
              <div className="absolute inset-0">
                <div className="container flex h-full">
                  <div className="w-full mt-auto bg-purple-light extend after:bg-purple-light aspect-w-326 aspect-h-144"></div>
                </div>
              </div>
              <div data-aos="fade-right" className="w-3/4">
                <GatsbyImage image={getImage(employerBrandingMobile)} alt="Employer Branding" />
              </div>
            </div>
            <div className="container grid-cols-12 lg:grid">
              <div className="relative z-10 hidden col-start-1 col-end-6 lg:block">
                <div className="absolute inset-0 flex">
                  <div data-aos="fade-right" className="absolute right-0">
                    <GatsbyImage image={getImage(employerBrandingDesktop)} alt="Employer Branding" />
                  </div>
                </div>
              </div>
              <div className="relative grid-cols-11 col-start-2 col-end-13 bg-purple-light lg:grid lg:mt-24 py-clamp-18-38">
                <div className="absolute inset-0">
                  <div className="w-full h-full bg-purple-light extend before:bg-purple-light lg:before:bg-transparent after:bg-purple-light"></div>
                </div>
                <div className="relative grid grid-cols-6 col-start-5 col-end-11">
                  <div className="col-span-6 lg:pl-20">
                    <h2
                      data-aos="stagger"
                      className="text-title"
                      dangerouslySetInnerHTML={{
                        __html: splitText({
                          "text-white": "Interested in",
                          "text-gray-darkest": "employer branding?",
                        }),
                      }}
                    />
                  </div>
                  <div className="col-start-4 col-end-7">
                    <Button link="/contact" className="text-gray-darkest mt-clamp-11-17">
                      Let's talk
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <section className="pt-clamp-44-62">
            <AltAboutJk>
              <AltAboutJk.Default />
            </AltAboutJk>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default EmployerBrandBuyInGuidePage

export const EmployerBrandBuyInQuery = graphql`
  query employerBrandBuyInQuery {
    employerBrandingDesktop: file(relativePath: { eq: "hci/hci-employer-branding-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 830)
      }
    }
    employerBrandingMobile: file(relativePath: { eq: "hci/hci-employer-branding-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    buyInGuideDesktop: file(relativePath: { eq: "hci/buy-in-guide-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    buyInGuideMobile: file(relativePath: { eq: "hci/buy-in-guide-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
